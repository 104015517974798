import { Box, Button, VStack, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TeamForm from "./TeamForm";
import TeamList from "./TeamList";
import { apiCall } from "../../../utils/apiUtility";
import { teamsQueryMap } from "../../../utils/queryMapUtil";
import { useAuth } from "../../Auth/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

import { Client, Team, Season, Page } from "../../../utils/apiTypes";
import { useClient } from "../../Contexts/ClientContext";
import PlayerManagement from "../Player/PlayerManagement";
import TeamUploadButton from "./TeamUploadButton";
import { useSeason } from "../../Contexts/SeasonContext";
import { useOrganization } from "../../Contexts/OrganizationContext";
import {useUser} from "../../User/UserContext";
import SeasonStatsManagement from "../SeasonStats/SeasonStatsManagement";


function TeamManagement() {
    const [teams, setTeams] = useState<Team[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('date');
    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
    const [selectedTeam, setSelectedTeam] = React.useState<Team | null>(null);
    const [mode, setMode] = React.useState("list");
    const { client } = useClient();
    const { season } = useSeason();
    const { currentUser } = useAuth();
    const { organization } = useOrganization();
    const { role } = useUser(); //
    const location = useLocation();

    useEffect(() => {
        setMode("list");
    }, [location]);

    const queryMap = teamsQueryMap(client, organization, season);

    useEffect(() => {
        if (currentUser) {
            queryMap.sort = 'name';
            queryMap.direction = 'ASC';
            queryMap.page = currentPage;
            queryMap.size = pageSize;
            apiCall<Page<Team>>('/api/v1/admin/teams', currentUser, "GET", null, queryMap)
                .then(teamsData => {
                    setTeams(teamsData.content);
                    setTotalPages(teamsData.totalPages);
                })
                .catch(error => {
                    console.error("Error fetching teams:", error);
                });
        }
    }, [currentUser, currentPage, pageSize, sortField, sortDirection]);

    const handleCreateUpdateTeam = (team: { id?: number; name: string; client: Client }) => {
        if (currentUser) {
            const method = team.id ? 'PUT' : 'POST';
            const url = team.id ? `/api/v1/admin/teams/${team.id}` : '/api/v1/admin/teams';

            apiCall<Team>(url, currentUser, method, team)
                .then(updatedTeam => {
                    if (team.id) {
                        const updatedTeams = teams.map(c => c.id === team.id ? updatedTeam : c);
                        setTeams(updatedTeams);
                    } else {
                        setTeams([...teams, updatedTeam]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating team:', error));
        }
    };

    const handleDeleteTeam = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/teams/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setTeams(teams.filter(c => c.id !== id));
                })
                .catch(error => console.error('Error deleting team:', error));
        }
    };

    const handleSort = (newSortField: string) => {
        setSortField(newSortField);
        setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const navigate = useNavigate();

    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading>Teams</Heading>
                    <TeamList
                        teams={teams}
                        onDelete={handleDeleteTeam}
                        onEdit={(id: number) => {
                            const teamToEdit = teams.find(c => c.id === id) || null;
                            setSelectedTeam(teamToEdit);
                            setMode("edit");
                        }}
                        onView={(id: number) => {
                            const teamToView = teams.find(team => team.id === id);
                            setSelectedTeam(teamToView || null);
                            setMode("view");
                        }}
                        onShowStats={(id: number) => {
                            const teamToView = teams.find(team => team.id === id);
                            setSelectedTeam(teamToView || null);
                            setMode("stats");
                        }}
                        onSort={handleSort}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                        totalPages={totalPages}
                    />
                    <Button onClick={() => setMode("create")}>Add New Team</Button>

                    {/* Conditionally render TeamUploadButton based on role */}
                    {role !== 'OrganizationAdmin' && (
                        <TeamUploadButton season={season} client={client!} organization={organization} setTeams={setTeams} />
                    )}
                </>
            )}
            {mode === "create" && (
                <TeamForm onSubmit={handleCreateUpdateTeam} onCancel={() => setMode('list')} />
            )}
            {mode === "edit" && selectedTeam && (
                <TeamForm onSubmit={handleCreateUpdateTeam} onCancel={() => setMode('list')} initialData={selectedTeam} />
            )}
            {mode === "view" && selectedTeam && (
                <Box>
                    <Heading>{selectedTeam.name}</Heading>
                    <PlayerManagement
                        team={selectedTeam}
                        mode={mode}
                        setMode={setMode}
                        onBack={() => setMode("list")}
                    />
                </Box>
            )}
            {mode === "stats" && selectedTeam && (
                <Box>
                    <Heading>{selectedTeam.name}</Heading>
                    <SeasonStatsManagement team={selectedTeam!}/>
                </Box>
            )}
        </VStack>
    );
}

export default TeamManagement;
