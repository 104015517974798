import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    LinkOverlay,
    LinkBox,
    useColorModeValue,
    IconButton
} from "@chakra-ui/react";
import React, {useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {Season} from "../../../utils/apiTypes";
import {EditIcon} from "@chakra-ui/icons";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";
import {useUser} from "../../User/UserContext";


    function SeasonList({
                            seasons,
                            onDelete,
                            onEdit,
                            onView
                        }: {
        seasons: Season[];
        onDelete: (id: number) => void;
        onEdit: (id: number) => void;
        onView: (id: number) => void;
    }) {
        const rowHoverBg = useColorModeValue('gray.200', 'gray.700');
        const { role } = useUser();
    return (
        <Box>
            <Table variant="striped" colorScheme="teal">
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Name</Th>
                        {role !== 'OrganizationAdmin' && (
                        <Th>Actions</Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {seasons.map(season => (
                        <Tr key={season.id} _hover={{ bg: rowHoverBg }}>
                            <Td px={2} py={1}>{season.id}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                <LinkOverlay onClick={() => onView(season.id)}
                                             _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                             cursor="pointer"
                                >
                                    {season.name}
                                </LinkOverlay>
                                </LinkBox>
                            </Td>
                            <Td px={2} py={1}>{season?.client?.name}</Td>
                            {role !== 'OrganizationAdmin' && (
                            <Td px={2} py={1}>

                                <IconButton
                                    aria-label="Edit Player"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(season.id)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete Seasson"
                                    message="Are you sure you want to delete this season?"
                                    onDelete={() => onDelete(season.id)}
                                    label="Delete Season"
                                />
                            </Td>
                            )}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}

export default SeasonList;
