import React, { useState, useEffect } from "react";
import { Box, Button, VStack, Heading, Text } from "@chakra-ui/react";
import PlayerForm from "./PlayerForm";
import PlayerList from "./PlayerList";
import { apiCall } from "../../../utils/apiUtility";
import { useAuth } from "../../Auth/AuthContext";
import { useNavigate } from "react-router-dom";

import { Client, Team, Player } from "../../../utils/apiTypes";
import { useClient } from "../../Contexts/ClientContext";
import RosterUploadButton from "../Teams/RosterUploadButton";

interface PlayerManagementProps {
    team: Team;
    mode: string;
    setMode: React.Dispatch<React.SetStateAction<string>>;
    onBack: () => void;
}

const PlayerManagement: React.FC<PlayerManagementProps> = ({ team, onBack }) => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
    const [mode, setMode] = useState<"list" | "create" | "edit" | "view">("list");
    const { client } = useClient();
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            const url = team.id ? `/api/v1/admin/players/?teamId=${team.id}` : '/api/v1/admin/players';
            apiCall<Player[]>(url, currentUser)
                .then(playersData => setPlayers(playersData))
                .catch(error => console.error('Error fetching players:', error));
        }
    }, [currentUser, team.id]);

    const handleCreateUpdatePlayer = (player: Player) => {
        if (currentUser) {
            const method = player.id ? 'PUT' : 'POST';
            const url = player.id ? `/api/v1/admin/players/${player.id}` : '/api/v1/admin/players';

            apiCall<Player>(url, currentUser, method, player)
                .then(updatedPlayer => {
                    if (player.id) {
                        const updatedPlayers = players.map(p => p.id === player.id ? updatedPlayer : p);
                        setPlayers(updatedPlayers);
                    } else {
                        setPlayers([...players, updatedPlayer]);
                    }
                    setMode("list");
                })
                .catch(error => console.error('Error creating/updating player:', error));
        }
    };

    const handleDeletePlayer = (id: number) => {
        if (currentUser) {
            apiCall(`/api/v1/admin/players/${id}`, currentUser, 'DELETE')
                .then(() => {
                    setPlayers(players.filter(p => p.id !== id));
                })
                .catch(error => console.error('Error deleting player:', error));
        }
    };

    const handleViewPlayer = (id: number) => {
        const playerToView = players.find(player => player.id === id);
        setSelectedPlayer(playerToView || null);
        setMode("view");
    };

    const handleBackToTeam = () => {
        setMode("list");
        onBack();
    };

    return (
        <VStack spacing={4} align="start">
            {mode === "list" && (
                <>
                    <Heading size="md">Roster for {team.name}</Heading>
                    <PlayerList
                        players={players}
                        onDelete={handleDeletePlayer}
                        onEdit={(id: number) => {
                            const playerToEdit = players.find(p => p.id === id) || null;
                            setSelectedPlayer(playerToEdit);
                            setMode("edit");
                        }}
                        onView={handleViewPlayer}
                    />
                    <Button onClick={() => setMode("create")}>Add New Player</Button>
                    <RosterUploadButton teamId={team.id} setPlayers={setPlayers} />
                    <Button onClick={handleBackToTeam}>Back to Teams</Button>
                </>
            )}
            {mode === "create" && (
                <PlayerForm
                    onSubmit={handleCreateUpdatePlayer}
                    onCancel={() => setMode("list")}
                    team={team}
                    client={client!}
                />
            )}
            {mode === "edit" && selectedPlayer && (
                <PlayerForm
                    onSubmit={handleCreateUpdatePlayer}
                    onCancel={() => setMode("list")}
                    team={team}
                    client={client!}
                    initialData={selectedPlayer}
                />
            )}
            {mode === "view" && selectedPlayer && (
                <Box>
                    <Heading size="md">Player Details</Heading>
                    <Text><strong>First Name:</strong> {selectedPlayer.firstName}</Text>
                    <Text><strong>Last Name:</strong> {selectedPlayer.lastName}</Text>
                    <Text><strong>Email:</strong> {selectedPlayer.email}</Text>
                    <Text><strong>Phone:</strong> {selectedPlayer.phone}</Text>
                    <Text><strong>Date of Birth:</strong> {selectedPlayer.dateOfBirth}</Text>
                    <Text><strong>Home Town:</strong> {selectedPlayer.homeTown}</Text>
                    <Text><strong>Height:</strong> {`${selectedPlayer.heightFeet}' ${selectedPlayer.heightInches}"`}</Text>
                    <Text><strong>Weight:</strong> {selectedPlayer.weight} lbs</Text>
                    <Text><strong>Jersey Number:</strong> {selectedPlayer.number}</Text>
                    <Text><strong>Position:</strong> {selectedPlayer.position}</Text>
                    <Text><strong>Last Team:</strong> {selectedPlayer.lastTeam}</Text>
                    <Button onClick={() => setMode("list")}>Back to List</Button>
                </Box>
            )}
        </VStack>
    );
};

export default PlayerManagement;

