import { IconButton, Box, Table, Thead, Tbody, Tr, Th, Td, Button, LinkBox, LinkOverlay, useColorModeValue } from "@chakra-ui/react";
import { ViewIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons"; // Ensure you have these icons
import React, {useState} from "react";
import {Player} from "../../../utils/apiTypes";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";


function PlayerList({
                        players,
                        onDelete,
                        onEdit,
                        onView
                    }: {
    players: Player[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onView: (id: number) => void;
}) {
    const rowHoverBg = useColorModeValue('gray.200', 'gray.700');
    return (
        <Box>
            <Table variant="striped" colorScheme="teal" size="sm"> {/* Adjust the size to "sm" */}
                <Thead>
                    <Tr>
                        <Th>Id</Th>
                        <Th>Number</Th>
                        <Th>Name</Th>
                        <Th>Shot</Th>
                        <Th>Height</Th>
                        <Th>Weight</Th>
                        <Th>Position</Th>
                        <Th>Grad  Year</Th>
                        <Th>Home Town</Th>
                        <Th>DOB</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {players.map(player => (
                        <Tr key={player.id} _hover={{ bg: rowHoverBg }}>
                            <Td px={2} py={1}>{player.id}</Td>
                            <Td px={2} py={1}>{player.number}</Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => onView(player.id!)}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >
                                        {player.firstName} {player.lastName}
                                    </LinkOverlay>
                                </LinkBox>
                            </Td>
                            <Td px={2} py={1}>{player.shot}</Td>
                            <Td px={2} py={1}>{player.heightFeet}' {player.heightInches}"</Td>
                            <Td px={2} py={1}>{player.weight}</Td>
                            <Td px={2} py={1}>{player.position}</Td>
                            <Td px={2} py={1}>{player.graduationYear}</Td>
                            <Td px={2} py={1}>{player.homeTown}</Td>
                            <Td px={2} py={1}>{player.dateOfBirth}</Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit Player"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(player.id!)}
                                    size="sm"
                                    variant="ghost" />
                                <DeleteConfirmationIcon
                                    title="Delete Player"
                                    message="Are you sure you want to delete this player?"
                                    onDelete={() => onDelete(player.id!)}
                                    label="Delete Player"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>

            </Table>
        </Box>
    );
}

export default PlayerList;
