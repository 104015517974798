import React, { createContext, useContext, ReactNode } from 'react';

interface UserState {
    role?: string;
}

interface UserContextProps extends UserState {
    setRole: (role: string) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = React.useState<UserState>(() => {
        const storedRole = localStorage.getItem('userRole'); // Retrieve role from storage
        return { role: storedRole || undefined };
    });

    const setRole = (role: string) => {
        localStorage.setItem('userRole', role); // Persist role
        setUser({ role });
    };

    React.useEffect(() => {
        console.log('Updated role:', user.role); // Debugging
    }, [user.role]);

    return (
        <UserContext.Provider value={{ ...user, setRole }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextProps => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

